import { String, Static } from "runtypes";
import UUID from "pure-uuid";

export const RequestId = String.withConstraint((_) => {
  try {
    new UUID(_);
    return true;
  } catch {
    return false;
  }
}).withBrand("RequestId");

export type RequestId = Static<typeof RequestId>;

export class RequestIdExtensions {
  static generate = (): RequestId => {
    return RequestId.check(new UUID(4).toString());
  };

  static checkOrGenerate = (input: any): RequestId => {
    const vres = RequestId.validate(input);

    if (!vres.success) {
      return RequestIdExtensions.generate();
    }

    return vres.value;
  };
}
