import React from "react";
import { AppProvider, Frame } from "@shopify/polaris";
import "@shopify/polaris/dist/styles.css";
import enTranslations from "@shopify/polaris/locales/en.json";

export const PolarisContextProvider: React.FC = ({ children }) => {
  return (
    <AppProvider i18n={enTranslations}>
      <Frame>{children}</Frame>
    </AppProvider>
  );
};
