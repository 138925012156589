import { Button } from "@shopify/polaris";
import React, { useContext, useState } from "react";
import { Redirect } from "@shopify/app-bridge/actions";
import { Context } from "@shopify/app-bridge-react";
import { useServiceContext } from "../../../contexts/ServiceContext";
import { useAlertContext } from "../../../contexts/AlertContext";
import { useShopifyContext } from "../../../contexts/shopify/ShopifyContext";
import { BillingMoney } from "@tsukiy0/shopify-app-core";

export const BillingActivateButton: React.FC = () => {
  const { billingService } = useServiceContext();
  const [loading, setLoading] = useState(false);
  const { onError } = useAlertContext();
  const app = useContext(Context);
  const redirect = Redirect.create(app!);
  const { shopId } = useShopifyContext();

  const onActivate = async () => {
    try {
      setLoading(true);
      const res = await billingService.createUsageSubscription(
        {
          shopId,
          cappedAmount: BillingMoney.check(10)
        },
      );
      redirect.dispatch(Redirect.Action.REMOTE, res.authorizeUrl);
    } catch (e) {
      onError({
        error: e,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button primary size="slim" loading={loading} onClick={onActivate}>
      Activate
    </Button>
  );
};
