import { OrderId, OrderVerificationDetail, OrderVerification, OrderIdExtensions, OrderVerificationExtensions } from "@app/core";
import { Timestamp, TimestampExtensions } from "@app/utils";
import { Context } from "@shopify/app-bridge-react";
import { Redirect } from "@shopify/app-bridge/actions";
import { IndexTable, useIndexResourceState } from "@shopify/polaris";
import React, { useContext } from "react";
import { Waypoint } from "react-waypoint";
import { IndexTableRowPrimaryLink } from "../../components/IndexTableRowPrimaryLink";
import { FinancialStatusBadge } from "./FinancialStatusBadge";
import { FormattedDate } from "./FormattedDate";
import { FormattedMoney } from "./FormattedMoney";
import { FormattedOrderCustomerName } from "./FormattedOrderCustomerName";
import { FormattedOrderName } from "./FormattedOrderName";
import { FormattedOrderPhoneNumber } from "./FormattedOrderPhoneNumber";
import { FulfillmentStatusBadge } from "./FulfillmentStatusBadge";
import { OrderVerificationStatusBadge } from "./OrderVerificationStatusBadge";

export const OrderTable: React.FC<{
  items: readonly OrderVerificationDetail[];
  loading: boolean;
  bulkActions: {
    content: string;
    onAction: (values: OrderId[]) => void;
  }[];
  hasNext: boolean;
  onNext: () => void;
}> = ({ items, bulkActions, loading, onNext, hasNext }) => {
  const app = useContext(Context);
  const redirect = Redirect.create(app!);
  const itemsWithId = items.map((_) => {
    return {
      id: _.order.orderId,
      ..._,
    };
  });

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
  } = useIndexResourceState(itemsWithId);

  const orderIds = selectedResources.map((id) => {
    return OrderId.check(id);
  });

  const wrappedBulkActions = bulkActions.map((_) => {
    return {
      content: _.content,
      onAction: () => _.onAction(orderIds),
    };
  });

  const waypoint = <Waypoint onEnter={onNext} />;

  return (
    <IndexTable
      loading={loading}
      promotedBulkActions={wrappedBulkActions}
      headings={[
        { title: "Order" },
        { title: "Date" },
        { title: "Customer" },
        { title: "Phone" },
        { title: "Verification" },
        { title: "Total" },
        { title: "Payment" },
        { title: "Fulfillment" },
      ]}
      itemCount={itemsWithId.length}
      selectedItemsCount={
        allResourcesSelected ? "All" : selectedResources.length
      }
      onSelectionChange={handleSelectionChange}
    >
      {itemsWithId.map((_, i) => {
        return (
          <React.Fragment key={i}>
            {i === itemsWithId.length - 1 && hasNext ? waypoint : null}
            <IndexTable.Row
              id={_.id}
              key={_.id}
              selected={selectedResources.includes(_.id)}
              position={i}
              onNavigation={() => {
                redirect.dispatch(Redirect.Action.ADMIN_SECTION, {
                  name: Redirect.ResourceType.Order,
                  resource: {
                    id: OrderIdExtensions.getRawId(_.order.orderId),
                  },
                  newContext: true,
                });
              }}
            >
              <IndexTable.Cell>
                <IndexTableRowPrimaryLink>
                  <FormattedOrderName value={_.order} />
                </IndexTableRowPrimaryLink>
              </IndexTable.Cell>
              <IndexTable.Cell>
                <FormattedDate
                  value={TimestampExtensions.toDate(_.order.createdAt)}
                />
              </IndexTable.Cell>
              <IndexTable.Cell>
                <FormattedOrderCustomerName value={_.order} />
              </IndexTable.Cell>
              <IndexTable.Cell>
                <FormattedOrderPhoneNumber value={_.order} />
              </IndexTable.Cell>
              <IndexTable.Cell>
                {
                  _.orderVerification && (
                    <OrderVerificationStatusBadge
                      value={OrderVerificationExtensions.getLastEvent(_.orderVerification)}
                    />
                  )
                }
              </IndexTable.Cell>
              <IndexTable.Cell>
                <FormattedMoney value={_.order.totalPrice} />
              </IndexTable.Cell>
              <IndexTable.Cell>
                <FinancialStatusBadge value={_.order.financialStatus} />
              </IndexTable.Cell>
              <IndexTable.Cell>
                <FulfillmentStatusBadge value={_.order.fulfillmentStatus} />
              </IndexTable.Cell>
            </IndexTable.Row>
          </React.Fragment>
        );
      })}
    </IndexTable>
  );
};
