import { ShopId } from "@tsukiy0/shopify-app-core";
import { Record, Static } from "runtypes";
import { OrderId } from "../models/OrderId";

export const PrepareOrderVerificationRequest = Record({
  shopId: ShopId,
  orderId: OrderId,
});

export type PrepareOrderVerificationRequest = Static<
  typeof PrepareOrderVerificationRequest
>;
