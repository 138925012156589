import { FailReason, OrderVerificationEvent, OrderVerificationStatus } from "@app/core";
import { Badge } from "@shopify/polaris";
import React from "react";

const getReasonMessage = (reason: FailReason): string => {
  switch(reason) {
    case "NO_PHONE_NUMBER":
      return "No phone number";
    case "SMS_DELIVERY_FAILURE":
      return "Failed to send SMS";
    case "UNSUPPORTED_COUNTRY":
      return "Unsupported country";
    default:
      return "Unknown";
  };
};

export const OrderVerificationStatusBadge: React.FC<{
  value: OrderVerificationEvent;
}> = ({ value }) => {
  switch (value.status) {
    case "SENT":
      return (
        <Badge status="attention" progress="partiallyComplete">
          Sent
        </Badge>
      );
    case "FAILED":
      return (
        <Badge status="critical" progress="partiallyComplete">
          {getReasonMessage(value.reason)}
        </Badge>
      );
    case "CONFIRMED":
      return (
        <Badge status="success" progress="complete">
          Confirmed
        </Badge>
      );
    default:
      return null;
  }
};
