import { AppError } from "@app/utils";

export interface IApiClient {
  request<T>(path: string, body: any): Promise<T>;
  requestVoid(path: string, body: any): Promise<void>;
}

export class ApiError extends AppError {
  constructor() {
    super("Request failed");
  }
}
