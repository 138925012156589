import { AutomationSettings } from "../models/AutomationSettings";
import { FilterOrderVerificationRequest } from "../models/FilterOrderVerificationRequest";
import {
  PrepareOrderVerificationRequest,
} from "../handlers/PrepareOrderVerificationRequest";
import { IAutomationSettingsRepository } from "./IAutomationSettingsRepository";
import { IOrderService } from "./IOrderService";
import { GetAutomationSettingsRequest } from "../models/GetAutomationSettingsRequest";

export class AutomationPublicService {
  constructor(
    private readonly automationSettingsRepository: IAutomationSettingsRepository,
    private readonly orderService: IOrderService,
  ) {}

  getAutomationSettings = async (
    request: GetAutomationSettingsRequest
  ): Promise<AutomationSettings> => {
    return await this.automationSettingsRepository.get(request.shopId);
  };

  updateAutomationSettings = async (
    automationSettings: AutomationSettings,
  ): Promise<void> => {
    await this.automationSettingsRepository.put(automationSettings);
  };

  filterOrderVerification = async (
    request: FilterOrderVerificationRequest,
  ): Promise<PrepareOrderVerificationRequest | undefined> => {
    const [automationSettings, transactions] = await Promise.all([
      this.automationSettingsRepository.get(request.shopId),
      this.orderService.getTransactions(request.shopId, request.orderId),
    ]);

    if (!automationSettings.enabled) {
      return;
    }

    const anyCodTransactions = transactions.find((_) =>
      ["Cash on Delivery (COD)", "cash_on_delivery"].includes(_.gateway ?? ""),
    );


    if (!anyCodTransactions) {
      return;
    }

    return {
      shopId: request.shopId,
      orderId: request.orderId,
    };
  };
}
