
import { Order } from "@app/core";
import { TextStyle } from "@shopify/polaris";
import React from "react";

export const FormattedOrderCustomerName: React.FC<{
  value: Order;
}> = ({ value }) => {
  if (!value.customer) {
    return <TextStyle variation="subdued">No customer</TextStyle>;
  }

  return <TextStyle>{value.customer.name}</TextStyle>;
};
