import { CurrencyCode } from "@tsukiy0/shopify-admin-graphql-types";
import { Enum } from "@app/utils";
import { Record, Number, Static } from "runtypes";

export const Money = Record({
  amount: Number,
  currency: Enum(CurrencyCode),
});

export type Money = Static<typeof Money>;
