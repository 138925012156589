import { Region } from "@app/utils";
import { BillingMoney } from "@tsukiy0/shopify-app-core";
import { Record, Static } from "runtypes";

export const SmsPrice = Record({
  region: Region,
  amount: BillingMoney,
});

export type SmsPrice = Static<typeof SmsPrice>;
