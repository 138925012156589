import { Spinner } from "@shopify/polaris";
import React from "react";

export const LoadingPage: React.FC = () => {
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner accessibilityLabel="loading" size="large" />
    </div>
  );
};
