import { IApiClient } from "./IApiClient";
import {
  ListOrdersByStatusRequest,
  ListOrdersRequest,
  OrderVerificationDetailPage,
} from "@app/core";

export class OrderService {
  constructor(private readonly apiClient: IApiClient) {}

  listOrders = async (
    req: ListOrdersRequest,
  ): Promise<OrderVerificationDetailPage> => {
    const res = await this.apiClient.request("/api/private/order/list", req);

    return OrderVerificationDetailPage.check(res);
  };

  listOrdersByStatus = async (
    req: ListOrdersByStatusRequest,
  ): Promise<OrderVerificationDetailPage> => {
    const res = await this.apiClient.request(
      "/api/private/order/list-by-status",
      req,
    );
    return OrderVerificationDetailPage.check(res);
  };
}
