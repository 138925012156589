import { Number, Static } from "runtypes";

export const Timestamp = Number.withConstraint((_) => _ >= 0).withBrand("Timestamp");

export type Timestamp = Static<typeof Timestamp>;

export class TimestampExtensions {
  static fromDate = (date: Date): Timestamp => {
    return Timestamp.check(date.getTime());
  };

  static toDate = (input: Timestamp): Date => {
    return new Date(input);
  };

  static isAfter = (a: Timestamp, b: Timestamp): boolean => {
    return a >= b;
  };
}
