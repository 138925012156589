import { Toast } from "@shopify/polaris";
import React, { useContext, useState } from "react";

interface ErrorProps {
  error: Error;
  onRetry?: () => void;
}

interface Value {
  onInfo: (message: string) => void;
  onError: (props: ErrorProps) => void;
}

const AlertContext = React.createContext<Value>({} as any);

export const AlertContextProvider: React.FC = ({ children }) => {
  const [info, setSuccess] = useState<string>();
  const [error, setError] = useState<ErrorProps>();

  const onDismissSuccess = () => {
    setSuccess(undefined);
  };

  const onDismissError = () => {
    setError(undefined);
  };

  const onInfo = (message: string) => {
    setSuccess(message);
  };

  const onError = (props: ErrorProps) => {
    console.error(props.error);
    setError(props);
  };

  const onRetryError = () => {
    if (error?.onRetry) {
      error.onRetry();
    }
    onDismissError();
  };

  return (
    <AlertContext.Provider
      value={{
        onInfo,
        onError,
      }}
    >
      {children}
      {info && (
        <Toast content={info} onDismiss={onDismissSuccess} duration={2000} />
      )}
      {error && (
        <Toast
          content={error.error.message}
          onDismiss={onDismissError}
          action={
            error.onRetry
              ? {
                  content: "Retry",
                  onAction: onRetryError,
                }
              : undefined
          }
          error
        />
      )}
    </AlertContext.Provider>
  );
};

export const useAlertContext = (): Value => useContext(AlertContext);
