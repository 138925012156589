import { OrderDisplayFulfillmentStatus } from "@tsukiy0/shopify-admin-graphql-types";
import { Badge } from "@shopify/polaris";
import React from "react";

export const FulfillmentStatusBadge: React.FC<{
  value?: OrderDisplayFulfillmentStatus;
}> = ({ value }) => {
  switch (value) {
    case OrderDisplayFulfillmentStatus.InProgress:
      return (
        <Badge status="info" progress="partiallyComplete">
          In progress
        </Badge>
      );
    case OrderDisplayFulfillmentStatus.Fulfilled:
      return <Badge progress="complete">Fulfilled</Badge>;
    case OrderDisplayFulfillmentStatus.Open:
      return (
        <Badge status="attention" progress="incomplete">
          Open
        </Badge>
      );
    case OrderDisplayFulfillmentStatus.PartiallyFulfilled:
      return (
        <Badge status="warning" progress="partiallyComplete">
          Partially fulfilled
        </Badge>
      );
    case OrderDisplayFulfillmentStatus.PendingFulfillment:
      return (
        <Badge status="attention" progress="incomplete">
          Pending
        </Badge>
      );
    case OrderDisplayFulfillmentStatus.Restocked:
      return <Badge progress="complete">Restocked</Badge>;
    case OrderDisplayFulfillmentStatus.Unfulfilled:
      return (
        <Badge status="attention" progress="incomplete">
          Unfulfilled
        </Badge>
      );
    case OrderDisplayFulfillmentStatus.Scheduled:
      return (
        <Badge status="info" progress="incomplete">
          Unfulfilled
        </Badge>
      );
    default:
      return null;
  }
};
