import { Modal, TextContainer } from "@shopify/polaris";
import React, { useState } from "react";

export const ConfirmModal: React.FC<{
  title: string;
  message: string;
  onAction: () => Promise<void>;
  children: (props: { onOpen: () => void }) => React.ReactNode;
}> = ({ title, message, onAction, children }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);
  const onAction2 = async () => {
    setLoading(true);
    await onAction();
    setLoading(false);
    setOpen(false);
  };

  return (
    <>
      {children({ onOpen })}
      <Modal
        open={open}
        onClose={onClose}
        title={title}
        primaryAction={{
          content: "Verify",
          onAction: onAction2,
          loading,
        }}
      >
        <Modal.Section>
          <TextContainer>{message}</TextContainer>
        </Modal.Section>
      </Modal>
    </>
  );
};
