import { Record, Static } from "runtypes";
import { Timestamp, TimestampExtensions } from "./Timestamp";

export const TimeRange = Record({
  from: Timestamp,
  to: Timestamp,
}).withConstraint((_) => {
  return TimestampExtensions.isAfter(_.to, _.from);
});

export type TimeRange = Static<typeof TimeRange>;
