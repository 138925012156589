import { IOrderVerificationService } from "./IOrderVerificationService";
import { OrderId } from "../models/OrderId";
import {
  OrderVerification, OrderVerificationExtensions,
} from "../models/OrderVerification";
import { OrderVerificationEvent } from "../models/OrderVerificationEvent";
import {
  IOrderVerificationRepository,
  OrderVerificationNotFoundError,
} from "./IOrderVerificationRepository";
import { ShopId } from "@tsukiy0/shopify-app-core";

export class DefaultOrderVerificationService
  implements IOrderVerificationService {
  constructor(
    private readonly orderVerificationRepository: IOrderVerificationRepository,
  ) {}

  append = async (
    shopId: ShopId,
    orderId: OrderId,
    event: OrderVerificationEvent,
  ): Promise<OrderVerification> => {
    try {
      const ov = await this.orderVerificationRepository.get(shopId, orderId);
      const newOv = OrderVerificationExtensions.appendEvent(ov, event);

      await this.orderVerificationRepository.put(newOv);

      return newOv;
    } catch (err) {
      if (err instanceof OrderVerificationNotFoundError) {
        const newOv = {
          shopId,
          orderId,
          events: [event],
        };
        await this.orderVerificationRepository.put(newOv);

        return newOv;
      }

      throw err;
    }
  };
}
