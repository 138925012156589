import React, { useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { useAlertContext } from "../../contexts/AlertContext";
import { useServiceContext } from "../../contexts/ServiceContext";
import { AllOrdersTableFilters } from "./AllOrdersTableFilters";
import { OrderTable } from "./OrderTable";
import {
  ListOrdersRequest,
  OrderFilter,
  OrderId,
  OrderVerificationDetail,
} from "@app/core";
import { PageCursor } from "@app/utils";
import { CreateOrderVerificationModal } from "./CreateOrderVerificationModal";
import { useShopifyContext } from "../../contexts/shopify/ShopifyContext";

export const AllOrdersTable: React.FC = () => {
  const { shopId } = useShopifyContext();
  const { orderService } = useServiceContext();
  const [items, setItems] = useState<OrderVerificationDetail[]>([]);
  const [cursor, setCursor] = useState<PageCursor>(
    {
      limit: 20,
    },
  );
  const [loading, setLoading] = useState(false);
  const [orderIds, setOrderIds] = useState<readonly OrderId[]>([]);
  const { onError } = useAlertContext();
  const [filter, setFilter] = useState<OrderFilter>(
    {
      financialStatuses: [],
      fulfillmentStatuses: [],
      deliveryMethods: [],
      riskLevels: [],
    },
  );

  const buildRequest = useCallback(() => {
    return {
      shopId,
      filter,
      cursor,
    };
  }, [filter, cursor]);

  const onLoad = useCallback(
    async (req: ListOrdersRequest) => {
      try {
        setLoading(true);
        const r = await orderService.listOrders(req);
        setItems(r.items);
        setCursor(r.cursor);
        setLoading(false);
      } catch (e) {
        onError({
          error: e,
          onRetry: () => onLoad(req),
        });
      }
    },
    [onError, orderService],
  );

  const debouncedOnLoad = useCallback(debounce(onLoad, 500), [onLoad]);

  const onNext = async () => {
    try {
      setLoading(true);
      const r = await orderService.listOrders(buildRequest());
      setItems([...items, ...r.items]);
      setCursor(r.cursor);
      setLoading(false);
    } catch (e) {
      onError({
        error: e,
        onRetry: onNext,
      });
    }
  };

  useEffect(() => {
    void debouncedOnLoad(buildRequest());
  }, [filter]);

  return (
    <>
      <AllOrdersTableFilters value={filter} onChange={setFilter} />
      <CreateOrderVerificationModal
        orderIds={orderIds}
        onComplete={() => {
          setOrderIds([]);
          void debouncedOnLoad(buildRequest());
        }}
      >
        {({ onOpen }) => {
          return (
            <OrderTable
              items={items}
              loading={loading}
              hasNext={Boolean(cursor.key)}
              onNext={onNext}
              bulkActions={[
                {
                  content: "Verify",
                  onAction: (_) => {
                    setOrderIds(_);
                    onOpen();
                  },
                },
              ]}
            />
          );
        }}
      </CreateOrderVerificationModal>
    </>
  );
};
