import { ShopId } from "@tsukiy0/shopify-app-core";
import { Record, Static } from "runtypes";
import { OrderId } from "../models/OrderId";
import { OrderVerificationCode } from "../models/OrderVerificationCode";

export const ConfirmOrderVerificationRequest = Record({
  shopId: ShopId,
  orderId: OrderId,
  code: OrderVerificationCode,
});

export type ConfirmOrderVerificationRequest = Static<
  typeof ConfirmOrderVerificationRequest
>;
