import { IApiClient } from "./IApiClient";
import {
  AutomationSettings, GetAutomationSettingsRequest,
} from "@app/core";

export class AutomationService {
  constructor(private readonly apiClient: IApiClient) {}

  getAutomationSettings = async (req: GetAutomationSettingsRequest): Promise<AutomationSettings> => {
    const res = await this.apiClient.request(
      "/api/private/automation-settings/get",
      req,
    );

    return AutomationSettings.check(res);
  }

  updateAutomationSettings = async (
    req: AutomationSettings,
  ): Promise<void> => {
    await this.apiClient.requestVoid(
      "/api/private/automation-settings/update",
      req,
    );
  };
}
