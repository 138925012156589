import { PageCursor } from "@app/utils";
import { ShopId } from "@tsukiy0/shopify-app-core";
import { Record, Static } from "runtypes";
import { OrderVerificationStatus } from "./OrderVerificationStatus";

export const ListOrdersByStatusRequest = Record({
  shopId: ShopId,
  cursor: PageCursor,
  status: OrderVerificationStatus,
});

export type ListOrdersByStatusRequest = Static<
  typeof ListOrdersByStatusRequest
>;
