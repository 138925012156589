import React, { useContext } from "react";
import { useConfigContext } from "./ConfigContext";
import { useShopifyContext } from "./shopify/ShopifyContext";
import {
  AutomationService,
  BillingService,
  FetchApiClient,
  OrderService,
  OrderVerificationService,
} from "@app/web-infrastructure";

interface Value {
  billingService: BillingService;
  orderService: OrderService;
  orderVerificationService: OrderVerificationService;
  automationService: AutomationService;
}

const ServiceContext = React.createContext<Value>({} as any);

export const ServiceContextProvider: React.FC = ({ children }) => {
  const { getToken } = useShopifyContext();
  const { hostUrl: apiUrl } = useConfigContext();

  const apiClient = new FetchApiClient(apiUrl, getToken);

  return (
    <ServiceContext.Provider
      value={{
        billingService: new BillingService(apiClient),
        orderService: new OrderService(apiClient),
        orderVerificationService: new OrderVerificationService(apiClient),
        automationService: new AutomationService(apiClient),
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};

export const useServiceContext = (): Value => {
  return useContext(ServiceContext);
};
