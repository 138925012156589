import { IClock } from "@app/utils";
import {
  FailReason,
  OrderVerificationEvent,
} from "../models/OrderVerificationEvent";
import { ISmsServiceFactory, SenderId } from "../../sms";
import { SendOrderVerificationRequest } from "./SendOrderVerificationRequest";
import { IOrderVerificationService } from "../services/IOrderVerificationService";

export interface ISendOrderVerificationHandler {
  handle(request: SendOrderVerificationRequest): Promise<void>;
}

export class SendOrderVerificationHandler
  implements ISendOrderVerificationHandler {
  constructor(
    private readonly orderVerificationService: IOrderVerificationService,
    private readonly smsServiceFactory: ISmsServiceFactory,
    private readonly clock: IClock,
  ) {}

  handle = async (request: SendOrderVerificationRequest): Promise<void> => {
    try {
      await this.smsServiceFactory
        .get(request.test)
        .notify(
          request.phoneNumber,
          SenderId.check("Verify"),
          request.message,
        );
    } catch (err) {
      if (err instanceof Error) {
        await this.orderVerificationService.append(
          request.shopId,
          request.orderId,
          {
            status: "FAILED",
            created: this.clock.now(),
            reason: "SMS_DELIVERY_FAILURE",
          }
        );
      }

      throw err;
    }
  };
}
