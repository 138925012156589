import { PhoneNumber } from "@app/utils";
import { ShopId } from "@tsukiy0/shopify-app-core";
import { Boolean, Literal, Record, Static, String } from "runtypes";
import { OrderId } from "../models/OrderId";

export const SendOrderVerificationRequest = Record({
  shopId: ShopId,
  orderId: OrderId,
  phoneNumber: PhoneNumber,
  message: String.withConstraint((_) => _.length > 0),
  test: Boolean,
});

export type SendOrderVerificationRequest = Static<
  typeof SendOrderVerificationRequest
>;
