import { PageCursor, Page } from "@app/utils";
import { Static } from "runtypes";
import { OrderVerification } from "./OrderVerification";

export const OrderVerificationPage = Page(
  OrderVerification,
);

export type OrderVerificationPage = Static<
  typeof OrderVerificationPage
>;
