import { OrderId } from "../models/OrderId";
import { OrderVerification } from "../models/OrderVerification";
import { OrderVerificationEvent } from "../models/OrderVerificationEvent";
import { AppError, PageCursor } from "@app/utils";
import { OrderVerificationPage } from "../models/OrderVerificationPage";
import { ShopId } from "@tsukiy0/shopify-app-core";

export interface IOrderVerificationRepository {
  get(shopId: ShopId, orderId: OrderId): Promise<OrderVerification>;
  listByStatus(
    shopId: ShopId,
    status: OrderVerificationEvent["status"],
    cursor: PageCursor,
  ): Promise<OrderVerificationPage>;
  put(orderVerification: OrderVerification): Promise<void>;
}

export class OrderVerificationNotFoundError extends AppError {}
