import { IApiClient } from "./IApiClient";
import { SmsPricePage } from "@app/core";
import { 
  CreateUsageSubscriptionRequest, 
  CreateUsageSubscriptionResponse, 
  GetUsageSubscriptionRequest, 
  GetUsageSubscriptionResponse, 
  UpdateUsageSubscriptionCappedAmountRequest, 
  UpdateUsageSubscriptionCappedAmountResponse, 
} from "@tsukiy0/shopify-app-core";

export class BillingService {
  constructor(private readonly apiClient: IApiClient) {}

  getUsageSubscription = async (req: GetUsageSubscriptionRequest): Promise<GetUsageSubscriptionResponse> => {
    const res = await this.apiClient.request(
      "/shopify/billing/usage-subscription/get",
      req,
    );
    return GetUsageSubscriptionResponse.check(res);
  };

  createUsageSubscription = async (req: CreateUsageSubscriptionRequest): Promise<CreateUsageSubscriptionResponse> => {
    const res = await this.apiClient.request(
      "/shopify/billing/usage-subscription/create",
      req,
    );
    return CreateUsageSubscriptionResponse.check(res);
  };

  updateCappedAmount = async (
    req: UpdateUsageSubscriptionCappedAmountRequest,
  ): Promise<UpdateUsageSubscriptionCappedAmountResponse> => {
    const res = await this.apiClient.request(
      "/shopify/billing/usage-subscription/update-capped-amount",
      req,
    );
    return UpdateUsageSubscriptionCappedAmountResponse.check(res);
  };

  getSmsPricing = async (): Promise<SmsPricePage> => {
    const res = await this.apiClient.request("/api/private/billing/get-sms-pricing", {});
    return SmsPricePage.check(res);
  };
}
