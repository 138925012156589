import React, { useCallback, useEffect, useState } from "react";
import { useAlertContext } from "../../contexts/AlertContext";
import { useServiceContext } from "../../contexts/ServiceContext";
import { OrderTable } from "./OrderTable";
import {
  OrderId,
  OrderVerificationDetail,
  OrderVerificationStatus,
} from "@app/core";
import { PageCursor } from "@app/utils";
import { CreateOrderVerificationModal } from "./CreateOrderVerificationModal";
import { useShopifyContext } from "../../contexts/shopify/ShopifyContext";

export const StatusBucketOrdersTable: React.FC<{
  statusBucket: OrderVerificationStatus;
}> = ({ statusBucket }) => {
  const { shopId } = useShopifyContext();
  const { orderService } = useServiceContext();
  const [items, setItems] = useState<OrderVerificationDetail[]>([]);
  const [cursor, setCursor] = useState<PageCursor>(
    {
      key: undefined,
      limit: 20,
    },
  );
  const [loading, setLoading] = useState(false);
  const [orderIds, setOrderIds] = useState<readonly OrderId[]>([]);
  const { onError } = useAlertContext();

  const onLoad = useCallback(async () => {
    try {
      setLoading(true);
      const r = await orderService.listOrdersByStatus(
        {
          shopId,
          cursor,
          status: statusBucket,
        },
      );
      setItems(r.items);
      setCursor(r.cursor);
      setLoading(false);
    } catch (e) {
      onError({
        error: e,
        onRetry: onLoad,
      });
    }
  }, [onError, orderService, statusBucket]);

  const onNext = async () => {
    try {
      setLoading(true);
      const r = await orderService.listOrdersByStatus(
        {
          shopId,
          cursor,
          status: statusBucket,
        },
      );
      setItems([...items, ...r.items]);
      setCursor(r.cursor);
      setLoading(false);
    } catch (e) {
      onError({
        error: e,
        onRetry: onNext,
      });
    }
  };

  useEffect(() => {
    void onLoad();
  }, []);

  return (
    <CreateOrderVerificationModal
      orderIds={orderIds}
      onComplete={() => {
        setOrderIds([]);
        void onLoad();
      }}
    >
      {({ onOpen }) => {
        return (
          <OrderTable
            items={items}
            loading={loading}
            hasNext={Boolean(cursor?.key)}
            onNext={onNext}
            bulkActions={[
              {
                content: "Verify",
                onAction: (_) => {
                  setOrderIds(_);
                  onOpen();
                },
              },
            ]}
          />
        );
      }}
    </CreateOrderVerificationModal>
  );
};
