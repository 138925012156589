import { Literal, Record, Static, String, Union } from "runtypes";
import { PhoneNumber } from "@app/utils";
import { Timestamp } from "@app/utils";
import { OrderVerificationCode } from "./OrderVerificationCode";
import { BillingMoney } from "@tsukiy0/shopify-app-core";

export const SentEvent = Record({
  status: Literal("SENT"),
  created: Timestamp,
  message: String.withConstraint((_) => _.length > 0),
  phoneNumber: PhoneNumber,
  chargeAmount: BillingMoney,
  code: OrderVerificationCode,
});

export type SentEvent = Static<typeof SentEvent>;

export const ConfirmedEvent = Record({
  status: Literal("CONFIRMED"),
  created: Timestamp,
});

export type ConfirmedEvent = Static<typeof ConfirmedEvent>;

export const FailReason  = Union(
  Literal("NO_PHONE_NUMBER"),
  Literal("UNSUPPORTED_COUNTRY"),
  Literal("SMS_DELIVERY_FAILURE"),
  Literal("UNKNOWN"),
);

export type FailReason = Static<typeof FailReason>;

export const FailedEvent = Record({
  status: Literal("FAILED"),
  created: Timestamp,
  reason: FailReason,
});

export type FailedEvent = Static<typeof FailedEvent>;

export const OrderVerificationEvent = Union(
  SentEvent,
  ConfirmedEvent,
  FailedEvent,
);

export type OrderVerificationEvent = Static<
  typeof OrderVerificationEvent
>;
