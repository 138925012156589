import React from "react";
import getSymbolFromCurrency from "currency-symbol-map";
import { Money } from "@app/core";

export const FormattedMoney: React.FC<{
  value: Money;
}> = ({ value }) => {
  return (
    <>
      {getSymbolFromCurrency(value.currency)}
      {value.amount.toFixed(2)}
    </>
  );
};
