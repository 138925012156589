import { String, Static } from "runtypes";
import { PhoneNumberFormat } from "google-libphonenumber";
import { phoneNumberUtil } from "./phoneNumberUtil";

export const PhoneNumber = String.withConstraint((_) => {
    try {
      const pn = phoneNumberUtil.parse(_);
      phoneNumberUtil.format(pn, PhoneNumberFormat.E164);
      return true;
    } catch (e) {
      return false;
    }
  }).withBrand("PhoneNumber");

export type PhoneNumber = Static<typeof PhoneNumber>;
