import {
  Card,
  SkeletonBodyText,
  SkeletonDisplayText,
  Stack,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";
import { GetUsageSubscriptionRequest, UsageSubscription } from "@tsukiy0/shopify-app-core";
import React, { useCallback, useEffect, useState } from "react";
import { useAlertContext } from "../../../contexts/AlertContext";
import { useShopifyContext } from "../../../contexts/shopify/ShopifyContext";
import { useServiceContext } from "../../../contexts/ServiceContext";
import { BillingActivateButton } from "./BillingActivateButton";
import { BillingActiveBadge } from "./BillingActiveBadge";
import { IncreaseCappedAmountButton } from "./IncreaseCappedAmountButton";

export const BillingCard: React.FC = () => {
  const { billingService } = useServiceContext();
  const { shopId } = useShopifyContext();
  const [subscription, setSubscription] = useState<UsageSubscription>();
  const [loading, setLoading] = useState(true);
  const { onError } = useAlertContext();

  const onLoad = useCallback(async () => {
    try {
      setLoading(true);
      const res = await billingService.getUsageSubscription({
        shopId,
      });
      setSubscription(res.usageSubscription);
      setLoading(false);
    } catch (e) {
      onError({
        error: e,
        onRetry: onLoad,
      });
    }
  }, [billingService, onError]);

  useEffect(() => {
    void onLoad();
  }, []);

  const loadingMarkup = (
    <Card>
      <Card.Header title={<SkeletonDisplayText />} />
      <Card.Section>
        <SkeletonBodyText />
      </Card.Section>
    </Card>
  );

  return loading ? (
    loadingMarkup
  ) : (
    <Card>
      <Card.Header title="Billing">
        {subscription ? <BillingActiveBadge /> : <BillingActivateButton />}
      </Card.Header>
      <Card.Section>
        {subscription ? (
          <Stack>
            <Stack.Item fill>Usage</Stack.Item>
            <Stack.Item>
              <TextContainer>
                <div
                  style={{
                    textAlign: "right",
                  }}
                >
                  {`${subscription.balanceAmount} USD`}
                </div>
              </TextContainer>
              <TextContainer>
                <div
                  style={{
                    textAlign: "right",
                  }}
                >
                <TextStyle variation="subdued">
                  {`Max ${subscription.cappedAmount} USD`}
                </TextStyle>
                </div>
              </TextContainer>
              <TextContainer>
                <div
                  style={{
                    textAlign: "right",
                  }}
                >
                  <IncreaseCappedAmountButton />
                </div>
              </TextContainer>
            </Stack.Item>
          </Stack>
        ) : null}
      </Card.Section>
    </Card>
  );
};
