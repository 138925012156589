import { TimeRange } from "@app/utils";
import { Literal, Record, String, Array, Static, Union } from "runtypes";

export const OrderFilterStatus  = Union(
  Literal("OPEN"),
  Literal("CLOSED"),
  Literal("CANCELLED"),
)

export type OrderFilterStatus = Static<typeof OrderFilterStatus>;

export const OrderFilterFinancialStatus = Union(
  Literal("AUTHORIZED"),
  Literal("PAID"),
  Literal("PARTIALLY_REFUNDED"),
  Literal("PARTIALLY_PAID"),
  Literal("PENDING"),
  Literal("REFUNDED"),
  Literal("UNPAID"),
  Literal("VOIDED"),
)

export type OrderFilterFinancialStatus = Static<typeof OrderFilterFinancialStatus>;

export const OrderFilterFulfillmentStatus = Union(
  Literal("SHIPPED"),
  Literal("UNSHIPPED"),
  Literal("PARTIAL"),
  Literal("SCHEDULED"),
)

export type OrderFilterFulfillmentStatus = Static<typeof OrderFilterFulfillmentStatus>;

export const OrderFilterDeliveryMethod  = Union(
  Literal("LOCAL"),
  Literal("PICK_UP"),
  Literal("SHIPPING"),
)

export type OrderFilterDeliveryMethod = Static<typeof OrderFilterDeliveryMethod>;

export const OrderFilterRiskLevel  = Union(
  Literal("LOW"),
  Literal("MEDIUM"),
  Literal("HIGH"),
)

export type OrderFilterRiskLevel = Static<typeof OrderFilterRiskLevel>;

export const OrderFilter = Record({
  search: String.optional(),
  tag: String.optional(),
  timeRange: TimeRange.optional(),
  status: OrderFilterStatus.optional(),
  financialStatuses: Array(OrderFilterFinancialStatus),
  fulfillmentStatuses: Array(OrderFilterFulfillmentStatus),
  deliveryMethods: Array(OrderFilterDeliveryMethod),
  riskLevels: Array(OrderFilterRiskLevel),
});

export type OrderFilter = Static<typeof OrderFilter>;
