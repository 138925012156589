import {
  OrderDisplayFinancialStatus,
  OrderDisplayFulfillmentStatus,
} from "@tsukiy0/shopify-admin-graphql-types";
import { Enum, PhoneNumber, Timestamp } from "@app/utils";
import { Record, Static, String } from "runtypes";
import { Customer } from "./Customer";
import { OrderId } from "./OrderId";
import { Money } from "./Money";

export const Order = Record({
  orderId: OrderId,
  name: String,
  createdAt: Timestamp,
  totalPrice: Money,
  fulfillmentStatus: Enum(OrderDisplayFulfillmentStatus),
  financialStatus: Enum(OrderDisplayFinancialStatus),
  customer: Customer.optional(),
  phoneNumber: PhoneNumber.optional(),
});

export type Order = Static<typeof Order>;
