import React, { useContext } from "react";
import { DevShopifyContextProvider } from "./DevShopifyContext";
import { ProdShopifyContextProvider } from "./ProdShopifyContext";
import { ShopId } from "@tsukiy0/shopify-app-core";

type Value = {
  getToken: () => Promise<string>;
  shopId: ShopId;
};

const isDevelopment = process.env.NODE_ENV === "development";

export const ShopifyContext = React.createContext<Value>({} as any);

export const ShopifyContextProvider: React.FC = isDevelopment
  ? DevShopifyContextProvider
  : ProdShopifyContextProvider;

export const useShopifyContext = (): Value => useContext(ShopifyContext);
