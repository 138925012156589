import { Provider, useAppBridge } from "@shopify/app-bridge-react";
import React, { useEffect, useState } from "react";
import { useConfigContext } from "../../contexts/ConfigContext";
import { getSessionToken } from "@shopify/app-bridge-utils";
import { ShopId } from "@tsukiy0/shopify-app-core";
import { ShopifyContext } from "./ShopifyContext";
import { useQuery } from "./useQuery";

const ShopifyContextInnerProvider: React.FC<{ shopId: ShopId }> = ({
  shopId,
  children,
}) => {
  const app = useAppBridge();

  const getToken = async () => {
    return await getSessionToken(app);
  };

  return (
    <ShopifyContext.Provider
      value={{
        shopId,
        getToken,
      }}
    >
      {children}
    </ShopifyContext.Provider>
  );
};

export const ProdShopifyContextProvider: React.FC = ({ children }) => {
    const [shopId, setShopId] = useState<ShopId>();
    const { shopifyApiKey } = useConfigContext();
    const query = useQuery();

    useEffect(() => {
        setShopId(ShopId.check(query.shop));
    }, []);

    if (!shopId) {
        return null;
    }

    return (
    <Provider
        config={{
            apiKey: shopifyApiKey,
            host: query.host,
            forceRedirect: true,
        }}
    >
        <ShopifyContextInnerProvider shopId={shopId}>
            {children}
        </ShopifyContextInnerProvider>
    </Provider>
    );
};
