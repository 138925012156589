import { String, Static } from "runtypes";

export const OrderId = String.withConstraint((_) => _.startsWith("gid://shopify/Order/")).withBrand("OrderId");

export type OrderId = Static<typeof OrderId>;

export class OrderIdExtensions {
  static fromRawId = (value: number): OrderId => {
    return OrderId.check(`gid://shopify/Order/${value}`);
  }

  static getRawId = (input: OrderId): string => {
    return input.replace("gid://shopify/Order/", "");
  };
}
