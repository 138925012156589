import { SmsPricePage } from "../models/SmsPricePage";
import { ISmsPricingService } from "./ISmsPricingService";

export class SmsPricingPublicService {
  constructor(private readonly smsPricingService: ISmsPricingService) {}

  public get = async (): Promise<SmsPricePage> => {
    const page = await this.smsPricingService.get();
    return page
  };
}
