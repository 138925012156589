import { PhoneNumber } from "@app/utils";
import { Record, Static, Boolean } from "runtypes";

export const CheckOrderVerificationResponse = Record({
  sent: Boolean,
  details: Record({
    phoneNumber: PhoneNumber,
  }).optional(),
}).withConstraint((_) => {
  if (_.sent) {
    return _.details !== undefined;
  } else {
    return _.details === undefined;
  }
});

export type CheckOrderVerificationResponse = Static<
  typeof CheckOrderVerificationResponse
>;
