import { ApiError, IApiClient } from "./IApiClient";
import fetch from "cross-fetch";

export class FetchApiClient implements IApiClient {
  constructor(
    private readonly hostUrl: string,
    private readonly getToken?: () => Promise<string>,
  ) {}

  request = async <T>(path: string, body: any): Promise<T> => {
    const res = await this.do(path, body);

    return (await res.json()) as T;
  };

  requestVoid = async (path: string, body: any): Promise<void> => {
    await this.do(path, body);
  };

  private do = async (path: string, body: any) => {
    const url = new URL(path, this.hostUrl);
    const res = await fetch(url.toString(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: this.getToken ? `Bearer ${await this.getToken()}` : "",
      },
      body: JSON.stringify(body),
    });

    if (res.status !== 200) {
      throw new ApiError();
    }

    return res;
  };
}
