import { ShopId } from "@tsukiy0/shopify-app-core";
import { Literal, Record, Static } from "runtypes";
import { OrderId } from "../models/OrderId";

export const CheckOrderVerificationRequest = Record({
  shopId: ShopId,
  orderId: OrderId,
});

export type CheckOrderVerificationRequest = Static<
  typeof CheckOrderVerificationRequest
>;
