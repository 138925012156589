import { Page, Tabs } from "@shopify/polaris";
import { TabDescriptor } from "@shopify/polaris/dist/types/latest/src/components/Tabs/types";
import React from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { OrderListPage } from "../pages/OrderListPage/OrderListPage";
import { SettingsPage } from "../pages/SettingsPage/SettingsPage";

const Navigation: React.FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();

  const tabs: TabDescriptor[] = [
    {
      id: "/orders",
      content: "Orders",
    },
    {
      id: "/settings",
      content: "Settings",
    },
  ];

  const selected = tabs.findIndex((_) => _.id === location.pathname);

  return (
    <Tabs
      tabs={tabs}
      selected={selected}
      onSelect={(i) => {
        history.push({
          pathname: tabs[i].id,
        });
      }}
    >
      <Page fullWidth>{children}</Page>
    </Tabs>
  );
};

export const BasePage: React.FC = () => {
  return (
    <Switch>
      <Route
        exact
        path="/orders"
        render={() => {
          return (
            <Navigation>
              <OrderListPage />
            </Navigation>
          );
        }}
      />
      <Route
        exact
        path="/settings"
        render={() => {
          return (
            <Navigation>
              <SettingsPage />
            </Navigation>
          );
        }}
      />
      <Route
        render={() => {
          return <Redirect to="/orders" />;
        }}
      />
    </Switch>
  );
};
