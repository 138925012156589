import { OrderId } from "./OrderId";
import { Array, Record, Static } from "runtypes";
import {
  OrderVerificationEvent,
  SentEvent,
} from "./OrderVerificationEvent";
import { ShopId } from "@tsukiy0/shopify-app-core";

export const OrderVerification = Record({
  shopId: ShopId,
  orderId: OrderId,
  events: Array(OrderVerificationEvent).withConstraint(
    (_) => _.length > 0,
  ),
});

export type OrderVerification = Static<typeof OrderVerification>;

export class OrderVerificationExtensions {
  static getLastEvent = (ov: OrderVerification): OrderVerificationEvent => {
    const events = [...ov.events].sort(
      (a, b) => a.created - b.created,
    );
    return events[events.length - 1];
  };

  static getLastSentEvent = (ov: OrderVerification): SentEvent => {
    const events = [...ov.events]
      .sort((a, b) => a.created - b.created)
      .filter((_) => _.status === "SENT");
    return events[events.length - 1] as SentEvent;
  };

  static appendEvent = (
    ov: OrderVerification,
    event: OrderVerificationEvent,
  ): OrderVerification => {
    return {
      ...ov,
      events: [...ov.events, event],
    };
  };
}
