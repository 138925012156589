import { ShopId } from "@tsukiy0/shopify-app-core";
import { Provider } from "@shopify/app-bridge-react";
import React from "react";
import { useConfigContext } from "../ConfigContext";
import { ShopifyContext } from "./ShopifyContext";

const ShopifyContextInnerProvider: React.FC = ({ children }) => {
  const getToken = async () => {
    return process.env.DEV_TOKEN!;
  };

  return (
    <ShopifyContext.Provider
      value={{
        shopId: ShopId.check(process.env.DEV_SHOP_ID!),
        getToken,
      }}
    >
      {children}
    </ShopifyContext.Provider>
  );
};

export const DevShopifyContextProvider: React.FC = ({ children }) => {
  const { shopifyApiKey } = useConfigContext();
  const shopHost = btoa(`${process.env.DEV_SHOP_ID!}/admin`);

  return (
    <Provider
      config={{
        apiKey: shopifyApiKey,
        host: shopHost,
        forceRedirect: false,
      }}
    >
      <ShopifyContextInnerProvider>{children}</ShopifyContextInnerProvider>
    </Provider>
  );
};
