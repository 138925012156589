import { AppError, PageCursor } from "@app/utils";
import { ShopId, Url } from "@tsukiy0/shopify-app-core";
import { Order } from "../models/Order";
import { OrderFilter } from "../models/OrderFilter";
import { OrderId } from "../models/OrderId";
import { OrderPage } from "../models/OrderPage";
import { OrderTransaction } from "../models/OrderTransaction";

export interface IOrderService {
  get(shopId: ShopId, orderId: OrderId): Promise<Order>;
  list(
    shopId: ShopId,
    cursor: PageCursor,
    filter: OrderFilter,
  ): Promise<OrderPage>;
  getTransactions(shopId: ShopId, orderId: OrderId): Promise<OrderTransaction[]>
  getStatusUrl(shopId: ShopId, orderId: OrderId): Promise<Url>
}

export class OrderNotFoundError extends AppError {}
