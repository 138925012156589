import { IApiClient } from "./IApiClient";
import {
  CheckOrderVerificationRequest,
  CheckOrderVerificationResponse,
  ConfirmOrderVerificationRequest,
  PrepareOrderVerificationRequest,
} from "@app/core";

export class OrderVerificationService {
  constructor(private readonly apiClient: IApiClient) {}
  confirm = async (request: ConfirmOrderVerificationRequest): Promise<void> => {
    await this.apiClient.requestVoid("/api/public/order-verification/confirm", request);
  };

  prepare = async (request: PrepareOrderVerificationRequest): Promise<void> => {
    await this.apiClient.requestVoid("/api/private/order-verification/prepare", request);
  };

  check = async (
    request: CheckOrderVerificationRequest,
  ): Promise<CheckOrderVerificationResponse> => {
    return await this.apiClient.request(
      "/api/public/order-verification/check",
      request,
    );
  };
}
