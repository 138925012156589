import { Layout } from "@shopify/polaris";
import React from "react";
import { AutomationSettingsCard } from "./AutomationSettingsCard/AutomationSettingCard";
import { BillingCard } from "./BillingCard/BillingCard";
import { PricingCard } from "./PricingCard/PricingCard";

export const SettingsPage: React.FC = () => {
  return (
    <Layout>
      <Layout.Section oneHalf>
        <Layout>
          <Layout.Section>
            <BillingCard />
          </Layout.Section>
          <Layout.Section>
            <PricingCard />
          </Layout.Section>
        </Layout>
      </Layout.Section>
      <Layout.Section oneHalf>
        <Layout>
          <Layout.Section>
            <AutomationSettingsCard />
          </Layout.Section>
        </Layout>
      </Layout.Section>
    </Layout>
  );
};
