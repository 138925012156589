import { AppError } from "../models/AppError";

export interface IConfiguration {
  get(key: string): string;
}

export class KeyNotFoundError extends AppError {
  public constructor(public readonly key: string) {
    super();
  }
}
