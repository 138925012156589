import { AppError, PhoneNumber } from "@app/utils";
import { SenderId } from "../models/SenderId";

export interface ISmsService {
  notify(
    phoneNumber: PhoneNumber,
    senderId: SenderId,
    message: string,
  ): Promise<void>;
}

export class SendFailedError extends AppError {}
