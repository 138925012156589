import { AutomationSettings, GetAutomationSettingsRequest } from "@app/core";
import { Card, SkeletonBodyText, SkeletonDisplayText } from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { useAlertContext } from "../../../contexts/AlertContext";
import { useServiceContext } from "../../../contexts/ServiceContext";
import { useShopifyContext } from "../../../contexts/shopify/ShopifyContext";
import { AutomationSettingsEnableButton } from "./AutomationSettingsEnableButton";

export const AutomationSettingsCard: React.FC = () => {
  const { automationService } = useServiceContext();
  const { onError, onInfo } = useAlertContext();
  const { shopId } = useShopifyContext();
  const [
    automationSettings,
    setAutomationSettings,
  ] = useState<AutomationSettings>();
  const [enabledLoading, setEnabledLoading] = useState(false);

  const onLoad = async () => {
    try {
      setAutomationSettings(
        await automationService.getAutomationSettings({
          shopId,
        }),
      );
    } catch (e) {
      onError(e);
    }
  };

  const onToggleEnabled = async () => {
    try {
      setEnabledLoading(true);
      if (automationSettings) {
        const newAutomationSettings = AutomationSettings.check({
          ...automationSettings,
          enabled: !automationSettings.enabled,
        });

        await automationService.updateAutomationSettings(newAutomationSettings);
        setAutomationSettings(newAutomationSettings);
        onInfo("Updated");
      }
    } catch (e) {
      onError(e);
    } finally {
      setEnabledLoading(false);
    }
  };

  useEffect(() => {
    void onLoad();
  }, []);

  const loadingMarkup = (
    <Card>
      <Card.Header title={<SkeletonDisplayText />} />
      <Card.Section>
        <SkeletonBodyText />
      </Card.Section>
    </Card>
  );

  if (!automationSettings) {
    return loadingMarkup;
  }

  return (
    <Card>
      <Card.Header title="Automation">
        <AutomationSettingsEnableButton
          enabled={automationSettings.enabled}
          onClick={onToggleEnabled}
          loading={enabledLoading}
        />
      </Card.Header>
      <Card.Section>
        SMS is <em>{automationSettings.enabled ? "sent" : "not sent"}</em> for
        new Cash on Delivery (COD) orders.
      </Card.Section>
    </Card>
  );
};
