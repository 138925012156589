import React, { useContext, useEffect, useState } from "react";
import fetch from "cross-fetch";
import { LoadingPage } from "../components/LoadingPage";
import { AppError } from "@app/utils";

export class GenericConficContext<T> {
  private readonly ConfigContext = React.createContext<T>({} as any);

  private getConfig = async (): Promise<T> => {
    const res = await fetch("./config.json");

    if (res.status !== 200) {
      throw new ConfigNotFoundError();
    }

    const config = await res.json();

    return config;
  };

  ConfigContextProvider: React.FC = ({ children }) => {
    const [value, setValue] = useState<T>();

    const onLoad = async () => {
      const config = await this.getConfig();
      setValue(config);
    };

    useEffect(() => {
      onLoad();
    }, []);

    if (!value) {
      return <LoadingPage />;
    }

    return (
      <this.ConfigContext.Provider value={value}>{children}</this.ConfigContext.Provider>
    );
  };

  useConfigContext = (): T => useContext(this.ConfigContext);
}

class ConfigNotFoundError extends AppError {}
