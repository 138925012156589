import React, { useContext, useEffect, useState } from "react";
import { useAlertContext } from "./AlertContext";

type Value = {
  hostUrl: string;
  shopifyApiKey: string;
};

const ConfigContext = React.createContext<Value>({} as any);

export const ConfigContextProvider: React.FC = ({ children }) => {
  const [value, setValue] = useState<Value>();
  const { onError } = useAlertContext();

  useEffect(() => {
    try {
      const hostUrl = process.env.HOST_URL!;
      const shopifyApiKey = process.env.SHOPIFY_API_KEY!;

      setValue({
        hostUrl,
        shopifyApiKey,
      });
    } catch (err) {
      onError({
        error: err,
      });
    }
  }, []);

  if (!value) {
    return null;
  }

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};

export const useConfigContext = (): Value => useContext(ConfigContext);
