import { OrderVerificationExtensions } from "../models/OrderVerification";
import { IOrderVerificationRepository, OrderVerificationNotFoundError } from "../services/IOrderVerificationRepository";
import { CheckOrderVerificationRequest } from "./CheckOrderVerificationRequest";
import { CheckOrderVerificationResponse } from "./CheckOrderVerificationResponse";

export interface ICheckOrderVerificationHandler {
  handle(request: CheckOrderVerificationRequest): Promise<CheckOrderVerificationResponse>;
}

export class CheckOrderVerificationHandler {
  constructor(
    private readonly orderVerificationRepository: IOrderVerificationRepository,
  ) {}

  handle = async (
    request: CheckOrderVerificationRequest,
  ): Promise<CheckOrderVerificationResponse> => {
      const orderVerification = await this.getOrderVerification(request);

      if (!orderVerification) {
        return {
          sent: false
        };
      }

      const lastEvent = OrderVerificationExtensions.getLastEvent(orderVerification);

      if (lastEvent.status !== "SENT") {
        return {
          sent: false
        };
      }

      return {
        sent: true,
        details: {
          phoneNumber: lastEvent.phoneNumber
        }
      }
  };

  private getOrderVerification = async (request: CheckOrderVerificationRequest) => {
    try {
      return await this.orderVerificationRepository.get(
        request.shopId,
        request.orderId,
      );
    } catch(err) {
      if (err instanceof OrderVerificationNotFoundError) {
        return undefined;
      }

      throw err;
    }
  }
}
