import { Card, Tabs } from "@shopify/polaris";
import React, { useState } from "react";
import { StatusBucketOrdersTable } from "./StatusBucketOrdersTable";
import { AllOrdersTable } from "./AllOrdersTable";

export const OrderListPage: React.FC = () => {
  const [selected, setSelected] = useState(0);

  return (
    <Card>
      <Tabs
        tabs={[
          {
            id: "All",
            content: "All",
          },
          {
            id: "Sent",
            content: "Sent",
          },
          {
            id: "Confirmed",
            content: "Confirmed",
          },
          {
            id: "Failed",
            content: "Failed",
          },
        ]}
        selected={selected}
        onSelect={setSelected}
      >
        {selected === 0 && <AllOrdersTable />}
        {selected === 1 && (
          <StatusBucketOrdersTable
            statusBucket="SENT"
          />
        )}
        {selected === 2 && (
          <StatusBucketOrdersTable
            statusBucket="CONFIRMED"
          />
        )}
        {selected === 3 && (
          <StatusBucketOrdersTable
            statusBucket="FAILED"
          />
        )}
      </Tabs>
    </Card>
  );
};
