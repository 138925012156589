import { PageCursor } from "@app/utils";
import { ShopId } from "@tsukiy0/shopify-app-core";
import { Record, Static } from "runtypes";
import { OrderFilter } from "./OrderFilter";

export const ListOrdersRequest = Record({
  shopId: ShopId,
  filter: OrderFilter,
  cursor: PageCursor,
});

export type ListOrdersRequest = Static<typeof ListOrdersRequest>;
