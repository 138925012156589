import { ScriptTagDisplayScope, WebhookSubscriptionTopic } from "@tsukiy0/shopify-admin-graphql-types";
import { IScriptTagService, IWebhookService, ShopId, Url } from "@tsukiy0/shopify-app-core";

export class InstallPublicService {
  constructor(
    private readonly webhookService: IWebhookService,
    private readonly scriptTagService: IScriptTagService,
    private readonly config: {
      webhookUrl: Url
      scriptUrl: Url
    }
  ) {}

  onComplete = async (shopId: ShopId): Promise<void> => {
    await this.webhookService.create(
      shopId,
      WebhookSubscriptionTopic.OrdersCreate,
      this.config.webhookUrl
    );

    await this.scriptTagService.create({
      shopId,
      url: this.config.scriptUrl,
      scope: ScriptTagDisplayScope.OrderStatus,
    });
  }
}
