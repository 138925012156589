import { Record, Literal, String, Static } from "runtypes";
import countries from "i18n-iso-countries";
import lang from "i18n-iso-countries/langs/en.json";
import { phoneNumberUtil } from "./phoneNumberUtil";
import { PhoneNumber } from "./PhoneNumber";

countries.registerLocale(lang);

export const Region = String.withConstraint((_) => _.length === 2).withConstraint(
  (_) => _ === _.toUpperCase(),
).withBrand("Region");

export type Region = Static<typeof Region>;

export class RegionExtensions {
  static fromPhoneNumber = (input: PhoneNumber): Region => {
    return Region.check(
      phoneNumberUtil.getRegionCodeForNumber(
        phoneNumberUtil.parse(input),
      )!,
    );
  };

  static getCountryName = (region: Region): string => {
    return countries.getName(region, "en");
  };
}
