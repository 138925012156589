import { ActionList, Button, Popover } from "@shopify/polaris";
import React, { useContext, useState } from "react";
import { Redirect } from "@shopify/app-bridge/actions";
import { Context } from "@shopify/app-bridge-react";
import { useServiceContext } from "../../../contexts/ServiceContext";
import { useAlertContext } from "../../../contexts/AlertContext";
import { useShopifyContext } from "../../../contexts/shopify/ShopifyContext";
import { BillingMoney } from "@tsukiy0/shopify-app-core";

export const IncreaseCappedAmountButton: React.FC = () => {
  const [active, setActive] = useState(false);
  const { billingService } = useServiceContext();
  const [loading, setLoading] = useState(false);
  const { onError } = useAlertContext();
  const app = useContext(Context);
  const redirect = Redirect.create(app!);
  const { shopId } = useShopifyContext();

  const onAction = async (increaseAmount: BillingMoney) => {
    try {
      setLoading(true);
      const res = await billingService.updateCappedAmount(
        {
          shopId,
          addAmount: increaseAmount,
        },
      );
      redirect.dispatch(Redirect.Action.REMOTE, res.authorizeUrl);
    } catch (e) {
      onError({
        error: e,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popover
      active={active}
      onClose={() => setActive(false)}
      activator={
        <Button
          plain
          size="slim"
          loading={loading}
          onClick={() => setActive(!active)}
        >
          Increase max
        </Button>
      }
    >
      <ActionList
        items={[10, 20, 50, 100].map((_) => {
          return {
            content: `+${_} USD`,
            onAction: () => onAction(BillingMoney.check(_)),
          };
        })}
      />
    </Popover>
  );
};
