import {
  Card,
  DataTable,
  Filters,
  SkeletonBodyText,
  SkeletonDisplayText,
  SortDirection,
} from "@shopify/polaris";
import React, { useEffect, useState } from "react";
import { SmsPrice } from "@app/core";
import { useServiceContext } from "../../../contexts/ServiceContext";
import { useAlertContext } from "../../../contexts/AlertContext";
import { Region, RegionExtensions } from "@app/utils";
import styled from "styled-components";

const Container = styled.div`
  .Polaris-DataTable__ScrollContainer {
    height: 400px;
  }
`;

type SmsPriceDetail = SmsPrice & {
  countryName: string;
};

type PriceListFilter = (input: SmsPriceDetail[]) => SmsPriceDetail[];

type Direction = 1 | 0 | -1;

const filterBySearch: (search: string) => PriceListFilter = (search) => (
  input,
) => {
  return input.filter((_) =>
    _.countryName.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
  );
};

const sortByCountryName: (direction: Direction) => PriceListFilter = (
  direction,
) => (input) => {
  return input.sort((a, b) => {
    if (a.countryName < b.countryName) {
      return direction * -1;
    }

    if (a.countryName > b.countryName) {
      return Number(direction);
    }

    return 0;
  });
};

const sortByPrice: (direction: Direction) => PriceListFilter = (direction) => (
  input,
) => {
  return input.sort((a, b) => {
    if (a.amount < b.amount) {
      return direction * -1;
    }

    if (a.amount > b.amount) {
      return Number(direction);
    }

    return 0;
  });
};

const useFilter = (priceList: SmsPrice[]) => {
  const [countryNameDir, setCountryNameDir] = useState<Direction>(1);
  const [priceDir, setPriceDir] = useState<Direction>(0);
  const [search, setSearch] = useState<string>("");

  const priceDetailList = priceList.map((_) => {
    return {
      ..._,
      countryName: RegionExtensions.getCountryName(_.region),
    };
  });

  const filteredList = [
    sortByCountryName(countryNameDir),
    sortByPrice(priceDir),
    filterBySearch(search),
  ].reduce((acc, filter) => filter(acc), priceDetailList);

  const onClearAll = () => {
    setCountryNameDir(0);
    setPriceDir(0);
    setSearch("");
  };

  return {
    setCountryNameDir,
    setPriceDir,
    search,
    setSearch,
    onClearAll,
    filteredList,
  };
};

const useSmsPrices = () => {
  const { billingService } = useServiceContext();
  const [prices, setPrices] = useState<SmsPrice[]>([]);
  const [loading, setLoading] = useState(true);
  const { onError } = useAlertContext();

  const onLoad = async () => {
    try {
      setLoading(true);
      const r = await billingService.getSmsPricing();
      setPrices(r.items);
      setLoading(false);
    } catch (e) {
      onError({
        error: e,
        onRetry: onLoad,
      });
    }
  };

  useEffect(() => {
    void onLoad();
  }, []);

  return {
    prices,
    loading,
  };
};

export const PricingCard: React.FC = () => {
  const { prices, loading } = useSmsPrices();
  const {
    setCountryNameDir,
    setPriceDir,
    search,
    setSearch,
    onClearAll,
    filteredList,
  } = useFilter(prices);

  const loadingMarkup = (
    <Card>
      <Card.Header title={<SkeletonDisplayText />} />
      <Card.Section>
        <SkeletonBodyText />
      </Card.Section>
      <Card.Section>
        <SkeletonBodyText />
      </Card.Section>
    </Card>
  );

  return loading ? (
    loadingMarkup
  ) : (
    <Container>
      <Card>
        <Card.Header title="SMS Pricing" />
        <Card.Section>
          <Filters
            queryValue={search}
            onQueryChange={setSearch}
            onQueryClear={() => setSearch("")}
            filters={[]}
            onClearAll={onClearAll}
          />
        </Card.Section>
        <DataTable
          columnContentTypes={["text", "numeric"]}
          sortable={[true, true]}
          defaultSortDirection="ascending"
          onSort={(index, dir) => {
            setPriceDir(0);
            setCountryNameDir(0);

            const dirMap: Record<SortDirection, Direction> = {
              ascending: 1,
              descending: -1,
              none: 0,
            };

            if (index === 0) {
              setCountryNameDir(dirMap[dir]);
            }

            if (index === 1) {
              setPriceDir(dirMap[dir]);
            }
          }}
          headings={["Country", "Price (USD)"]}
          rows={filteredList.map((_) => {
            return [_.countryName, _.amount.toFixed(2)];
          })}
        />
      </Card>
    </Container>
  );
};
