import React from "react";
import { BrowserRouter } from "react-router-dom";
import { BasePage } from "./components/BasePage";
import { AlertContextProvider } from "./contexts/AlertContext";
import { ConfigContextProvider } from "./contexts/ConfigContext";
import { ServiceContextProvider } from "./contexts/ServiceContext";
import { PolarisContextProvider } from "./contexts/shopify/PolarisContext";
import { ShopifyContextProvider } from "./contexts/shopify/ShopifyContext";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <PolarisContextProvider>
        <AlertContextProvider>
          <ConfigContextProvider>
            <ShopifyContextProvider>
              <ServiceContextProvider>
                <BasePage />
              </ServiceContextProvider>
            </ShopifyContextProvider>
          </ConfigContextProvider>
        </AlertContextProvider>
      </PolarisContextProvider>
    </BrowserRouter>
  );
};

export default App;
