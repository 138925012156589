import { OrderDisplayFinancialStatus } from "@tsukiy0/shopify-admin-graphql-types";
import { Badge } from "@shopify/polaris";
import React from "react";

export const FinancialStatusBadge: React.FC<{
  value?: OrderDisplayFinancialStatus;
}> = ({ value }) => {
  switch (value) {
    case OrderDisplayFinancialStatus.Pending:
      return (
        <Badge status="warning" progress="incomplete">
          Pending
        </Badge>
      );
    case OrderDisplayFinancialStatus.Authorized:
      return (
        <Badge status="attention" progress="incomplete">
          Authorized
        </Badge>
      );
    case OrderDisplayFinancialStatus.Paid:
      return <Badge progress="complete">Paid</Badge>;
    case OrderDisplayFinancialStatus.PartiallyPaid:
      return (
        <Badge status="warning" progress="partiallyComplete">
          Partially paid
        </Badge>
      );
    case OrderDisplayFinancialStatus.PartiallyRefunded:
      return <Badge progress="partiallyComplete">Partially refunded</Badge>;
    case OrderDisplayFinancialStatus.Refunded:
      return <Badge progress="complete">Refunded</Badge>;
    case OrderDisplayFinancialStatus.Voided:
      return <Badge progress="complete">Voided</Badge>;
    case OrderDisplayFinancialStatus.Expired:
      return (
        <Badge status="warning" progress="incomplete">
          Expired
        </Badge>
      );
    default:
      return null;
  }
};
