import { AppError, IClock } from "@app/utils";
import { OrderVerification, OrderVerificationExtensions } from "../models/OrderVerification";
import { OrderVerificationEvent } from "../models/OrderVerificationEvent";
import { IOrderVerificationRepository } from "../services/IOrderVerificationRepository";
import { ConfirmOrderVerificationRequest } from "./ConfirmOrderVerificationRequest";
import { OrderVerificationStatus } from "../models/OrderVerificationStatus";

export interface IConfirmOrderVerificationHandler {
  handle(request: ConfirmOrderVerificationRequest): Promise<void>;
}

export class ConfirmOrderVerificationHandler
  implements IConfirmOrderVerificationHandler {
  constructor(
    private readonly orderVerificationRepository: IOrderVerificationRepository,
    private readonly clock: IClock,
  ) {}

  handle = async (request: ConfirmOrderVerificationRequest): Promise<void> => {
    const orderVerification = await this.orderVerificationRepository.get(
      request.shopId,
      request.orderId,
    );

    const lastEvent = OrderVerificationExtensions.getLastEvent(
      orderVerification,
    );
    const lastSentEvent = OrderVerificationExtensions.getLastSentEvent(
      orderVerification,
    );

    if (lastEvent.status === "CONFIRMED") {
      return;
    }

    const match = request.code === lastSentEvent.code;

    if (!match) {
      throw new BadCodeError();
    }

    const newOv = OrderVerificationExtensions.appendEvent(
      orderVerification,
      {
        status: "CONFIRMED",
        created: this.clock.now(),
      },
    );

    await this.orderVerificationRepository.put(newOv);
  };
}

export class BadCodeError extends AppError {}
