import { OrderId, PrepareOrderVerificationRequest } from "@app/core";
import React from "react";
import { ConfirmModal } from "../../components/ConfirmModal";
import { useAlertContext } from "../../contexts/AlertContext";
import { useServiceContext } from "../../contexts/ServiceContext";
import { useShopifyContext } from "../../contexts/shopify/ShopifyContext";

export const CreateOrderVerificationModal: React.FC<{
  orderIds: readonly OrderId[];
  onComplete: () => void;
  children: (props: { onOpen: () => void }) => React.ReactNode;
}> = ({ orderIds, onComplete, children }) => {
  const { shopId } = useShopifyContext();
  const { orderVerificationService } = useServiceContext();
  const { onError, onInfo } = useAlertContext();

  const onCreate = async () => {
    try {
      await Promise.all(
        orderIds.map(async (_) => {
          await orderVerificationService.prepare(
            {
              shopId,
              orderId: _,
            },
          );
        }),
      );

      onInfo("Sent");
    } catch (e) {
      onError({
        error: e,
        onRetry: onCreate,
      });
    } finally {
      onComplete();
    }
  };

  return (
    <ConfirmModal
      title={`Verify ${orderIds.length} orders`}
      message="This will send a verification SMS to your customers."
      onAction={onCreate}
    >
      {({ onOpen }) => {
        return children({ onOpen });
      }}
    </ConfirmModal>
  );
};
