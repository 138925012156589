import { Button } from "@shopify/polaris";
import React from "react";

export const AutomationSettingsEnableButton: React.FC<{
  enabled: boolean;
  loading: boolean;
  onClick: () => void;
}> = ({ enabled, loading, onClick }) => {
  return (
    <Button primary={!enabled} size="slim" loading={loading} onClick={onClick}>
      {enabled ? "Disable" : "Enable"}
    </Button>
  );
};
