import { AppError, IClock, RegionExtensions } from "@app/utils";
import { ISmsPricingService } from "../../sms";
import { Order } from "../models/Order";
import { OrderVerificationCode } from "../models/OrderVerificationCode";
import {
  FailReason,
  OrderVerificationEvent,
} from "../models/OrderVerificationEvent";
import {
  SendOrderVerificationRequest,
} from "./SendOrderVerificationRequest";
import { IOrderService } from "../services/IOrderService";
import { IOrderVerificationCodeGenerator } from "../services/IOrderVerificationCodeGenerator";
import { PrepareOrderVerificationRequest } from "./PrepareOrderVerificationRequest";
import { IOrderVerificationService } from "../services/IOrderVerificationService";
import { IAppUsageSubscriptionService, Url } from "@tsukiy0/shopify-app-core";

export interface IPrepareOrderVerificationHandler {
  handle(
    request: PrepareOrderVerificationRequest,
  ): Promise<SendOrderVerificationRequest>;
}

export class PrepareOrderVerificationHandler
  implements IPrepareOrderVerificationHandler {
  constructor(
    private readonly orderVerificationService: IOrderVerificationService,
    private readonly orderService: IOrderService,
    private readonly smsPricingService: ISmsPricingService,
    private readonly subscriptionService: IAppUsageSubscriptionService,
    private readonly clock: IClock,
    private readonly orderVerificationCodeGenerator: IOrderVerificationCodeGenerator,
  ) {}

  handle = async (
    request: PrepareOrderVerificationRequest,
  ): Promise<SendOrderVerificationRequest> => {
    try {
      const [order, orderStatusUrl, prices, subscription] = await Promise.all([
        this.orderService.get(request.shopId, request.orderId),
        this.orderService.getStatusUrl(request.shopId, request.orderId),
        this.smsPricingService.get(),
        this.subscriptionService.get(request.shopId),
      ]);

      const phoneNumber = order.phoneNumber;
      if (!phoneNumber) {
        throw new NoPhoneNumberError();
      }

      const region = RegionExtensions.fromPhoneNumber(phoneNumber);
      const price = prices.items.find((_) => _.region === region);
      if (!price) {
        throw new UnsupportedCountryError();
      }

      await this.subscriptionService.createCharge(
        request.shopId,
        price.amount,
        `SMS sent for order ${order.name}`,
      );

      const code = this.orderVerificationCodeGenerator.generate();
      const message = this.buildMessage(order, orderStatusUrl, code);

      await this.orderVerificationService.append(
        request.shopId,
        request.orderId,
        {
          status: "SENT",
          created: this.clock.now(),
          message,
          phoneNumber,
          chargeAmount: price.amount,
          code,
        },
      );

      return {
        shopId: request.shopId,
        orderId: request.orderId,
        phoneNumber,
        message: this.buildMessage(order, orderStatusUrl, code),
        test: subscription.test,
      };
    } catch (err) {
      if (err instanceof Error) {
        await this.orderVerificationService.append(
          request.shopId,
          request.orderId,
          {
            status: "FAILED",
            created: this.clock.now(),
            reason: this.toFailReason(err),
          },
        );
      }

      throw err;
    }
  };

  private buildMessage = (
    order: Order,
    orderStatusUrl: Url,
    code: OrderVerificationCode,
  ): string => {
    return `Confirmation code ${code} for order ${order.name} ${orderStatusUrl}`;
  };

  private toFailReason = (err: Error): FailReason => {
    if (err instanceof NoPhoneNumberError) {
      return "NO_PHONE_NUMBER";
    }

    if (err instanceof UnsupportedCountryError) {
      return "UNSUPPORTED_COUNTRY";
    }

    return "UNKNOWN";
  };
}

export class NoPhoneNumberError extends AppError {}
export class UnsupportedCountryError extends AppError {}
