import { ShopId } from "@tsukiy0/shopify-app-core";
import { ListOrdersByStatusRequest } from "../models/ListOrdersByStatusRequest";
import { ListOrdersRequest } from "../models/ListOrdersRequest";
import { OrderId } from "../models/OrderId";
import { OrderVerification } from "../models/OrderVerification";
import {
  OrderVerificationDetailPage,
} from "../models/OrderVerificationDetailPage";
import { IOrderService } from "./IOrderService";
import {
  IOrderVerificationRepository,
  OrderVerificationNotFoundError,
} from "./IOrderVerificationRepository";

export class OrderPublicService {
  constructor(
    private readonly orderService: IOrderService,
    private readonly orderVerificationRepository: IOrderVerificationRepository,
  ) {}

  listOrders = async (
    request: ListOrdersRequest,
  ): Promise<OrderVerificationDetailPage> => {
    const orderPage = await this.orderService.list(
      request.shopId,
      request.cursor,
      request.filter,
    );

    const items = await Promise.all(
      orderPage.items.map(async (_) => {
        const orderVerification = await this.tryGetOrderVerification(
          request.shopId,
          _.orderId,
        );
        return {
          order: _,
          orderVerification,
        };
      }),
    );

    return {
      items,
      cursor: orderPage.cursor,
    };
  };

  listOrdersByStatus = async (
    request: ListOrdersByStatusRequest,
  ): Promise<OrderVerificationDetailPage> => {
    const page = await this.orderVerificationRepository.listByStatus(
      request.shopId,
      request.status,
      request.cursor,
    );

    const items = await Promise.all(
      page.items.map(async (_) => {
        const order = await this.orderService.get(request.shopId, _.orderId);
        return {
          order,
          orderVerification: _,
        };
      }),
    );

    return {
      items,
      cursor: page.cursor,
    };
  };

  private tryGetOrderVerification = async (
    shopId: ShopId,
    orderId: OrderId,
  ): Promise<OrderVerification | undefined> => {
    try {
      const ov = await this.orderVerificationRepository.get(shopId, orderId);
      return ov;
    } catch (err) {
      if (err instanceof OrderVerificationNotFoundError) {
        return undefined;
      }
      throw err;
    }
  };
}
