import { Page } from "@app/utils";
import { Static } from "runtypes";
import { OrderVerificationDetail } from "./OrderVerificationDetail";

export const OrderVerificationDetailPage = Page(
  OrderVerificationDetail,
);

export type OrderVerificationDetailPage = Static<
  typeof OrderVerificationDetailPage
>;
