import {
  ChoiceList,
  DatePicker,
  Filters as PolarisFilters,
  TextField,
} from "@shopify/polaris";
import React, { useState } from "react";
import { format } from "date-fns";
import {
  OrderFilterDeliveryMethod,
  OrderFilterFulfillmentStatus,
  OrderFilterFinancialStatus,
  OrderFilterRiskLevel,
  OrderFilterStatus,
  OrderFilter,
} from "@app/core";
import {
  TimeRange,
  Timestamp,
  TimestampExtensions,
} from "@app/utils";

const prettyDate = (input: Timestamp): string => {
  return format(TimestampExtensions.toDate(input), "MMM dd, yyyy");
};

const prettyStatus = (input: OrderFilterStatus): string => {
  switch (input) {
    case "OPEN":
      return "Open";
    case "CLOSED":
      return "Closed";
    case "CANCELLED":
      return "Cancelled";
    default:
      throw new RangeError();
  }
};

const prettyPaymentStatus = (input: OrderFilterFinancialStatus): string => {
  switch (input) {
    case "AUTHORIZED":
      return "Authorized";
    case "PAID":
      return "Paid";
    case "PARTIALLY_REFUNDED":
      return "Partially refunded";
    case "PARTIALLY_PAID":
      return "Partially paid";
    case "PENDING":
      return "Pending";
    case "REFUNDED":
      return "Refunded";
    case "UNPAID":
      return "Unpaid";
    case "VOIDED":
      return "Voided";
    default:
      throw new TypeError();
  }
};

const prettyFulfillmentStatus = (
  input: OrderFilterFulfillmentStatus,
): string => {
  switch (input) {
    case "SHIPPED":
      return "Fulfilled";
    case "UNSHIPPED":
      return "Unfulfilled";
    case "PARTIAL":
      return "Partially Fulfilled";
    case "SCHEDULED":
      return "Scheduled";
    default:
      throw new TypeError();
  }
};

const prettyDeliveryMethod = (input: OrderFilterDeliveryMethod): string => {
  switch (input) {
    case "LOCAL":
      return "Local delivery";
    case "PICK_UP":
      return "Local pickup";
    case "SHIPPING":
      return "Ship to customer";
    default:
      throw new TypeError();
  }
};

const prettyRiskLevel = (input: OrderFilterRiskLevel): string => {
  switch (input) {
    case "HIGH":
      return "High";
    case "MEDIUM":
      return "Medium";
    case "LOW":
      return "Low";
    default:
      throw new TypeError();
  }
};
export const AllOrdersTableFilters: React.FC<{
  value: OrderFilter;
  onChange: (value: OrderFilter) => void;
}> = ({ value, onChange }) => {
  const today = new Date();
  const [{ month, year }, setMonthYear] = useState({
    month: today.getMonth(),
    year: today.getFullYear(),
  });

  const dateLabel = "Date";
  const tagLabel = "Tagged with";
  const statusLabel = "Status";
  const financialStatusLabel = "Payment status";
  const fulfillmentStatusLabel = "Fulfillment status";
  const deliveryMethodLabel = "Delivery method";
  const riskLevelLabel = "Risk Level";

  const appliedDateFilter =
    value.timeRange?.from && value.timeRange?.to
      ? [
          {
            key: dateLabel,
            label: `${prettyDate(value.timeRange.from)}-${prettyDate(
              value.timeRange.to,
            )}`,
            onRemove: () =>
              onChange({
                ...value,
                timeRange: undefined,
              }),
          },
        ]
      : [];

  const appliedTagFilter = value.tag
    ? [
        {
          key: tagLabel,
          label: `Tagged with ${value.tag}`,
          onRemove: () =>
            onChange({
              ...value,
              tag: undefined,
            }),
        },
      ]
    : [];

  const appliedStatusFilter = value.status
    ? [
        {
          key: statusLabel,
          label: prettyStatus(value.status),
          onRemove: () =>
            onChange({
              ...value,
              status: undefined,
            }),
        },
      ]
    : [];

  const appliedFinancialStatusFilter =
    value.financialStatuses.length !== 0
      ? [
          {
            key: financialStatusLabel,
            label: value.financialStatuses.map(prettyPaymentStatus).join(", "),
            onRemove: () =>
              onChange({
                ...value,
                financialStatuses: [],
              }),
          },
        ]
      : [];

  const appliedFulfillmentStatusFilter =
    value.fulfillmentStatuses.length !== 0
      ? [
          {
            key: fulfillmentStatusLabel,
            label: value.fulfillmentStatuses
              .map(prettyFulfillmentStatus)
              .join(", "),
            onRemove: () =>
              onChange({
                ...value,
                fulfillmentStatuses: [],
              }),
          },
        ]
      : [];

  const appliedDeliverMethodFilter =
    value.deliveryMethods.length !== 0
      ? [
          {
            key: deliveryMethodLabel,
            label: value.deliveryMethods.map(prettyDeliveryMethod).join(", "),
            onRemove: () =>
              onChange({
                ...value,
                deliveryMethods: [],
              }),
          },
        ]
      : [];

  const appliedRiskLevelFilter =
    value.riskLevels.length !== 0
      ? [
          {
            key: riskLevelLabel,
            label: value.riskLevels.map(prettyRiskLevel).join(", "),
            onRemove: () =>
              onChange({
                ...value,
                riskLevels: [],
              }),
          },
        ]
      : [];

  return (
    <div
      style={{
        padding: "16px",
      }}
    >
      <PolarisFilters
        filters={[
          {
            key: dateLabel,
            label: dateLabel,
            shortcut: true,
            filter: (
              <DatePicker
                month={month}
                year={year}
                onChange={(_) => {
                  onChange({
                    ...value,
                    timeRange: {
                      from: TimestampExtensions.fromDate(_.start),
                      to: TimestampExtensions.fromDate(_.end),
                    },
                  });
                }}
                onMonthChange={(_m, _y) => {
                  setMonthYear({ month: _m, year: _y });
                }}
                selected={
                  value.timeRange
                    ? {
                        start: TimestampExtensions.toDate(value.timeRange.from),
                        end: TimestampExtensions.toDate(value.timeRange.to),
                      }
                    : {
                        start: new Date(),
                        end: new Date(),
                      }
                }
                allowRange
              />
            ),
          },
          {
            key: statusLabel,
            label: statusLabel,
            shortcut: true,
            filter: (
              <ChoiceList
                title={statusLabel}
                titleHidden
                choices={[
                  {
                    value: 'OPEN',
                    label: 'Open'
                  },
                  {
                    value: 'CLOSED',
                    label: 'Closed'
                  },
                  {
                    value: 'CANCELLED',
                    label: 'Cancelled'
                  },
                ]}
                selected={value.status ? [value.status] : []}
                onChange={(_) => {
                  const status = _[0] as OrderFilterStatus;
                  onChange({ ...value, status });
                }}
              />
            ),
          },
          {
            key: financialStatusLabel,
            label: financialStatusLabel,
            shortcut: true,
            filter: (
              <ChoiceList
                title={financialStatusLabel}
                titleHidden
                allowMultiple
                choices={[
    {value: "AUTHORIZED",
      label: "Authorized"},
    {value: "PAID",
      label: "Paid"},
    {value: "PARTIALLY_REFUNDED",
      label: "Partially refunded"},
    {value: "PARTIALLY_PAID",
      label: "Partially paid"},
    {value: "PENDING",
      label: "Pending"},
    {value: "REFUNDED",
      label: "Refunded"},
    {value: "UNPAID",
      label: "Unpaid"},
    {value: "VOIDED",
      label: "Voided"},
                ]
                }
                selected={value.financialStatuses}
                onChange={(_) => {
                  onChange({
                    ...value,
                    financialStatuses: _ as OrderFilterFinancialStatus[],
                  });
                }}
              />
            ),
          },
          {
            key: fulfillmentStatusLabel,
            label: fulfillmentStatusLabel,
            shortcut: true,
            filter: (
              <ChoiceList
                title={fulfillmentStatusLabel}
                titleHidden
                allowMultiple
                choices={[
    {value: "SHIPPED",
      label: "Fulfilled",},
    {value: "UNSHIPPED",
      label: "Unfulfilled",},
    {value: "PARTIAL",
      label: "Partially Fulfilled",},
    {value: "SCHEDULED",
      label: "Scheduled",},
                ]}
                selected={value.fulfillmentStatuses}
                onChange={(_) => {
                  onChange({
                    ...value,
                    fulfillmentStatuses: _ as OrderFilterFulfillmentStatus[],
                  });
                }}
              />
            ),
          },
          {
            key: deliveryMethodLabel,
            label: deliveryMethodLabel,
            shortcut: true,
            filter: (
              <ChoiceList
                title={deliveryMethodLabel}
                titleHidden
                allowMultiple
                choices={[
    { value: "LOCAL",
      label: "Local delivery"},
    { value: "PICK_UP",
      label: "Local pickup"},
    { value: "SHIPPING",
      label: "Ship to customer"},
                ]}
                selected={value.deliveryMethods}
                onChange={(_) => {
                  onChange({
                    ...value,
                    deliveryMethods: _ as OrderFilterDeliveryMethod[],
                  });
                }}
              />
            ),
          },
          {
            key: riskLevelLabel,
            label: riskLevelLabel,
            shortcut: true,
            filter: (
              <ChoiceList
                title={riskLevelLabel}
                titleHidden
                allowMultiple
                choices={[
    { value: "HIGH",
      label: "High"},
    { value: "MEDIUM",
      label: "Medium"},
    { value: "LOW",
      label: "Low"},
                ]}
                selected={value.riskLevels}
                onChange={(_) => {
                  onChange({
                    ...value,
                    riskLevels: _ as OrderFilterRiskLevel[],
                  });
                }}
              />
            ),
          },
          {
            key: tagLabel,
            label: tagLabel,
            filter: (
              <TextField
                label={tagLabel}
                labelHidden
                value={value.tag}
                onChange={(_) => onChange({ ...value, tag: _ })}
              />
            ),
            shortcut: true,
          },
        ]}
        appliedFilters={[
          ...appliedDateFilter,
          ...appliedTagFilter,
          ...appliedStatusFilter,
          ...appliedFinancialStatusFilter,
          ...appliedFulfillmentStatusFilter,
          ...appliedDeliverMethodFilter,
          ...appliedRiskLevelFilter,
        ]}
        queryValue={value.search}
        onQueryChange={(_) => onChange({ ...value, search: _ })}
        onQueryClear={() => onChange({ ...value, search: undefined })}
        onClearAll={() => {
          onChange(
            {
              financialStatuses: [],
              fulfillmentStatuses: [],
              deliveryMethods: [],
              riskLevels: [],
            },
          );
        }}
      />
    </div>
  );
};
