import { Record, Static } from "runtypes";
import { Order } from "./Order";
import { OrderVerification } from "./OrderVerification";

export const OrderVerificationDetail = Record({
  order: Order,
  orderVerification: OrderVerification.optional(),
});

export type OrderVerificationDetail = Static<
  typeof OrderVerificationDetail
>;
