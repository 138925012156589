import { ShopId } from "@tsukiy0/shopify-app-core";
import { Record, Static } from "runtypes";
import { OrderId } from "./OrderId";

export const FilterOrderVerificationRequest = Record({
  shopId: ShopId,
  orderId: OrderId,
});

export type FilterOrderVerificationRequest = Static<
  typeof FilterOrderVerificationRequest
>;
