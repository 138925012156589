import { Order } from "@app/core";
import { TextStyle } from "@shopify/polaris";
import React from "react";

export const FormattedOrderPhoneNumber: React.FC<{
  value: Order;
}> = ({ value }) => {
  if (!value.phoneNumber) {
    return <TextStyle variation="subdued">No phone</TextStyle>;
  }

  return <TextStyle>{value.phoneNumber}</TextStyle>;
};